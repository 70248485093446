import React, { useCallback, useMemo, useState } from 'react';
import { Member } from 'src/models';
import { Typography, Box, useTheme, Theme } from '@mui/material';
import MemberProfileImage from '../Utils/MemberProfileImage';
import { Icon, IconName } from '@lubycon/icons';
import { grey } from '@mui/material/colors';

const SocialIcons: Record<string, IconName> = {
  github: 'logo-github',
  blog: 'logo-rss',
  linkedin: 'logo-linkedin',
  unsplash: 'camera',
  youtube: 'logo-youtube',
};

const IconLink = ({ name, url }: { name: string; url: string }) => {
  const { spacing } = useTheme<Theme>();
  const iconName = SocialIcons[name];
  return (
    <Box
      component="li"
      sx={{
        listStyle: 'none',
        margin: `0 ${spacing(1)}`,
        '& i': {
          color: 'text.secondary',
          transition: 'transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
          '&:hover': {
            transform: 'translate(0, -5px)',
            opacity: 0.8,
          },
        },
      }}
    >
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Icon name={iconName} color={grey['600']} />
      </a>
    </Box>
  );
};

interface Props {
  member: Member;
  backgroundColor?: string;
}
const MemberCard = ({ member, backgroundColor = 'transparent' }: Props) => {
  const { spacing } = useTheme<Theme>();
  const profileImage = member.profileImg;
  const [viewBoxSize, setViewboxSize] = useState(0);
  const ref = useCallback((el: HTMLElement | null) => {
    if (el != null) {
      setViewboxSize(el.clientWidth);
    }
  }, []);

  const socials = useMemo<Array<[string, string | undefined]>>(
    () => Object.entries(member.social).filter(([, socialUrl]) => Boolean(socialUrl)),
    [member.social]
  );
  console.log(socials);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box ref={ref}>
        <MemberProfileImage size={viewBoxSize} imageSrc={profileImage} backgroundColor={backgroundColor} />
      </Box>
      <Box sx={{ marginTop: 2, textAlign: 'center' }}>
        <Typography display="block" component="h3" variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {member.name.ko} / {member.name.en}
        </Typography>
        {member.company && <Typography variant="caption">{member.company}</Typography>}
        <Typography display="block" variant="caption">
          {member.role}
        </Typography>
      </Box>
      <Box>
        <Box
          component="ul"
          sx={{ margin: `${spacing(2)} 0 0 0`, padding: 0, display: 'flex', justifyContent: 'center' }}
        >
          {socials.length > 0 ? (
            socials.map(([name, socialUrl]) => <IconLink key={name} name={name} url={socialUrl ?? '#'} />)
          ) : (
            <Box component="li" sx={{ listStyle: 'none' }} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MemberCard;
