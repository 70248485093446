import { Card, Typography, Box, Button } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
  description?: string;
  image: string;
  onClick?: () => void;
}
const BenefitCard = ({ title, description, image, onClick }: Props) => {
  return (
    <Card
      sx={{
        border: 'none',
        padding: 2,
        cursor: 'pointer',
        transition: 'transform .2s ease-in',
        '&:hover': {
          transform: 'translateY(-10px)',
        },
      }}
      onClick={onClick}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box component="img" src={image} alt={title} sx={{ width: 120, marginTop: 1, marginBottom: 2 }} />
        <Typography
          variant="h6"
          sx={{
            marginBottom: '16px',
            fontSize: { md: 20, xs: 18 },
            textAlign: 'center',
            whiteSpace: 'pre-line',
          }}
        >
          {title}
        </Typography>
        {description ? <Typography sx={{ whiteSpace: 'pre-line', fontSize: 14 }}>{description}</Typography> : null}
      </Box>

      {onClick != null ? (
        <Box display="flex" justifyContent="flex-end" sx={{ marginTop: 2 }}>
          <Button size="small">자세히 보기</Button>
        </Box>
      ) : null}
    </Card>
  );
};

export default BenefitCard;
