import { useStaticQuery, graphql } from 'gatsby';
import { Member, MemberGroup } from 'src/models';

interface MembersResult {
  allMembersJson: {
    nodes: Member[];
  };
}

export function useAllMembers(group?: MemberGroup) {
  const {
    allMembersJson: { nodes: members },
  } = useStaticQuery<MembersResult>(graphql`
    query AllMembersQuery {
      allMembersJson {
        nodes {
          id
          group
          company
          name {
            en
            ko
          }
          profileImg
          role
          rss
          social {
            blog
            github
            linkedin
            unsplash
            youtube
          }
        }
      }
    }
  `);

  return group == null ? members : members.filter(member => member.group === group);
}
