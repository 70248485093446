import React, { HTMLAttributes } from 'react';
import { ImpressionArea, useBooleanState } from '@lubycon/react';
import { motion } from 'framer-motion';

interface Props extends HTMLAttributes<HTMLDivElement> {
  delay?: number;
  duration?: number;
}
const FadeUp = ({ children, delay, duration, ...props }: Props) => {
  const [isVisible, visible] = useBooleanState();

  return (
    <ImpressionArea onImpressionStart={visible}>
      <div {...props}>
        <motion.div
          variants={{
            hidden: {
              opacity: 0,
              y: '10%',
              visibility: 'hidden',
            },
            visible: {
              opacity: 1,
              y: 0,
              visibility: 'visible',
            },
          }}
          initial="hidden"
          animate={isVisible ? 'visible' : 'hidden'}
          transition={{ delay: delay ?? Math.random() / 1.5, duration }}
        >
          {children}
        </motion.div>
      </div>
    </ImpressionArea>
  );
};

export default FadeUp;
