import React from 'react';
import { Box } from '@mui/system';

interface Props {
  size: number;
  imageSrc: string;
  backgroundColor?: string;
}
const MemberProfileImage = ({ size, imageSrc, backgroundColor }: Props) => {
  const circleSize = size / 3;

  return (
    <>
      <Box
        component="svg"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        sx={{ verticalAlign: 'top' }}
      >
        <g>
          <clipPath id="member-profile-clippath">
            <circle cx={size / 2} cy={size - circleSize} r={circleSize} />
          </clipPath>
          <circle cx={size / 2} cy={size - circleSize} r={circleSize} fill={backgroundColor} stroke="none" />
        </g>
        <image clipPath="url(#member-profile-clippath)" height="100%" width="100%" href={imageSrc} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          padding: '25%',
          backgroundPosition: 'top',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% auto',
          top: 0,
          left: 0,
        }}
        style={{ backgroundImage: `url(${imageSrc})` }}
      />
    </>
  );
};

export default MemberProfileImage;
